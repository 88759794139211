import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group } from '@farm/types/group';

/**
 * The state of group list
 */
export type GroupsState = {
	groups: Group[] | null | undefined;
};

export const groupsInitialState: GroupsState = {
	groups: undefined,
};

export const groupsSlice = createSlice({
	name: 'groups',
	initialState: groupsInitialState as GroupsState,
	reducers: {
		/**
		 * Updates the groups in the Redux store state.
		 * @param state - Current state of groups in the Redux store.
		 * @param action - Redux action containing groups data or null.
		 * @returns New state with updated groups information.
		 */
		setGroups: (
			state: GroupsState,
			action: PayloadAction<Group[] | null>,
		): GroupsState => ({
			...state,
			groups: action.payload,
		}),
	},
});
