import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Group } from '@farm/types/group';
import { groupsSlice } from '../../redux/Groups';

/**
 * The object returned by `useStateGroup`
 */
type ReturnType = {
	groups: Group[] | null | undefined;
	// eslint-disable-next-line no-unused-vars
	setGroups: (groups: Group[] | null) => void;
};

/**
 * Custom hook for handling customer data in the Redux state.
 * @returns See `ReturnType`.
 */
const useStateGroup = (): ReturnType => {
	const dispatch = useDispatch();

	const groups = useSelector<RootState, Group[] | null | undefined>(
		(state: RootState) => state.group.groups,
	);

	/**
	 * Set the groups in the Redux store.
	 * @param groups - An array of Group objects or null if there are no devices.
	 */
	const setGroups = (groups: Group[] | null) => {
		dispatch(groupsSlice.actions.setGroups(groups));
	};

	return {
		groups,
		setGroups,
	};
};

export default useStateGroup;
